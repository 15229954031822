import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  view: (id:string) => `/fee/announcement/${id}`,
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/user/profile',
    login: '/auth/login',
    register: '/api/auth/register',
  },
  performance: {
    top: '/performance/performance',
    vote: (id:string) => `/performance/performance/${id}`,
    heart: (id:string) => `/performance/performance/add-heart/${id}`,
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  random: {
    guest: '/random/guest?page=1&perPage=-1',
    random: '/random/guest/random',
    delete: '/random/prize/remove-guest'
  },
  setting: {
    title: '/socket/setting?key=title',
    screen: '/socket/setting?key=screen',
    performance: '/socket/setting?key=performance',
    random: '/socket/setting?key=random',
    prize: '/socket/setting?key=prize',
    table: '/socket/setting?key=random table',
    update: (id:string) => `/socket/setting/${id}`
  },
  prize: {
    list: '/random/prize?page=1&perPage=-1',
    detail: (id:string) => `/random/prize/${id}`,
  },
};
