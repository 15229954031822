import { Navigate, useRoutes } from 'react-router-dom';
// config
//
import MainLayout from 'src/layouts/main';
import AuthClassicLayout from 'src/layouts/auth/classic';
import { GuestGuard } from 'src/auth/guard';
import { mainRoutes, HomePage, AdminPage } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },
    {
      path: '/admin',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <AdminPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}